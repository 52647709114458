import React, { useEffect, useState } from "react";
import "./first-form.styles.scss";
import {
  Alert,
  Autocomplete as MuiAutoComplete,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import Logo from "../../assets/logo.webp";
import Autocomplete from "react-google-autocomplete";
import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { MobileDatePicker, TimePicker } from "@mui/lab";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import Marker from "google-map-react";
import GoogleMapReact from "google-map-react";
import { Icon } from "@iconify/react";

var defaultTimeValue;
var defaultDateValue;
var addressSelected = "";
var minTime = moment("2013-02-08 08");
var maxTime = moment("2013-02-08 21");
const languages = require("language-list")();
const languagesList = [];
const multilineComponent = { style: { fontSize: 13 } };
var userFound = false;

const FirstForm = ({ match }) => {
  const defaultUtilityValue = (
    <MenuItem key="0" value={"N/A"}>
      N/A
    </MenuItem>
  );
  const params = useParams();
  const [itemID, setItemId] = React.useState(
    params.itemID ? params.itemID : ""
  );
  const [repLabel, setrepLabel] = React.useState(<p></p>);
  const [geometryLocation, setGeometryLocation] = useState({
    lat: 35.0873,
    lng: -106.65,
  });
  const [mapCoordinates, setMapCoordinates] = useState("35.0873, -106.65");
  const [selectedDate, setValue] = useState(new Date());
  const [timeValue, setTimeValue] = React.useState(
    new Date("2020-01-01 12:00")
  );
  const [UtilitySelect, setUtilityValue] = React.useState([
    defaultUtilityValue,
  ]);
  const [isInvalidUserError, setIsInvalidUserError] = React.useState(false);
  const [userErrorHelperText, setUserErrorHelperText] = React.useState("");
  const [roofFields, setroofFields] = React.useState("");
  const [submitSuccess, setSubmitSuccess] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const navigate = useNavigate();

  defaultTimeValue = moment(timeValue);
  defaultDateValue = moment(selectedDate);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const LocationPin = ({ text }) => (
    <div className="pin">
      <Icon icon="oi:map-marker" className="pin-icon" />
    </div>
  );

  const onSubmit = (data) => {
    if (data.Time === undefined) {
      data.Time = defaultTimeValue;
    }
    if (data.Date === undefined) {
      data.Date = defaultDateValue;
    }
    data.Date = data.Date.format("MM/DD/YYYY");
    data.Time = data.Time.format("HH:mm");
    data.AddressSelected = addressSelected;
    data.coordinates = mapCoordinates;
    data.itemID = itemID;
    if (userFound == false) {
      setUserErrorHelperText("Invalid Rep Id");
      setIsInvalidUserError(true);
      return;
    }
    setSubmitSuccess(false);
    setSubmitting(true);
    const requestOptions = {
      method: "post",
      headers: { "Content-Type": "application/json" },

      body: JSON.stringify(data),
    };
    fetch(
      "https://hook.integromat.com/mieuu93ohexwxy0dvp8ogrdabqe1170o",
      requestOptions
    ).then((res) => {
      if (res.ok) {
        setSubmitting(false);
        setSubmitSuccess(true);
        navigate("/submitSuccess");
      }
    });
  };

  useEffect(() => {
    languages
      .getData()
      .forEach((countryEntry) => languagesList.push(countryEntry.language));
    if (itemID != "") itemIdCallback();
  }, []);

  const OnRoofRadioChange = (event) => {
    if (event.target.value == "no") setroofFields();
    else {
      setroofFields(
        <div>
          {" "}
          <div className="align-left">
            <FormLabel component="legend">Roof type &nbsp;</FormLabel>
            <Controller
              render={({ field }) => (
                <Select {...field}>
                  <MenuItem value={"Shingle"}>Shingle</MenuItem>
                  <MenuItem value={"Tiled (12 Cent Adder)"}>
                    Tiled (12 Cent Adder)
                  </MenuItem>
                  <MenuItem value={"Metal (12 Cent Adder)"}>
                    Metal (12 Cent Adder)
                  </MenuItem>
                  <MenuItem value={"Flat (12 Cent Adder)"}>
                    Flat (12 Cent Adder)
                  </MenuItem>
                </Select>
              )}
              name="roofType"
              control={control}
              defaultValue={"Shingle"}
            />
          </div>
        </div>
      );
    }
  };
  const MondayApiCallback = (stateName) => {
    var query =
      "query { boards (ids:2139177078) { name items { group { title } name } } }";
    fetch("https://api.monday.com/v2", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjEyODIyMzY0NCwidWlkIjoyNTA1NjIwOSwiaWFkIjoiMjAyMS0xMC0xMVQxODo0Nzo1Ni4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6OTk2MTc1OCwicmduIjoidXNlMSJ9.Lo3aJk-3s_XAy-u1vQWY77OvZPtR9BlEFMNefXAh7tI",
      },
      body: JSON.stringify({
        query: query,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        UtilitySelect.length = 0;

        UtilitySelect.push(defaultUtilityValue);
        res.data.boards[0].items.forEach((item) => {
          if (item.group.title === stateName) {
            UtilitySelect.push(
              <MenuItem key={item.name} value={item.name}>
                {item.name}
              </MenuItem>
            );
            setUtilityValue(UtilitySelect);
          }
        });
      });
  };

  useEffect(() => {
    if (itemID.length == 10) {
      itemIdCallback();
    } else if (itemID.length > 0) {
      userFound = false;
      setrepLabel(<p className="error-message">Invalid sales rep id</p>);
    }
  }, [itemID]);

  const itemIdCallback = () => {
    userFound = false;
    setrepLabel(<p>Getting sales rep info...</p>);

    var query = `query { boards(ids:[2539932388]) { items (ids:${itemID}) { name column_values{ title value } } } }`;
    fetch("https://api.monday.com/v2", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjEyODIyMzY0NCwidWlkIjoyNTA1NjIwOSwiaWFkIjoiMjAyMS0xMC0xMVQxODo0Nzo1Ni4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6OTk2MTc1OCwicmduIjoidXNlMSJ9.Lo3aJk-3s_XAy-u1vQWY77OvZPtR9BlEFMNefXAh7tI",
      },
      body: JSON.stringify({
        query: query,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        res.data.boards.forEach((board) => {
          board.items.forEach((item) => {
            setrepLabel(
              <p>
                <b>Welcome {item.name}</b>
              </p>
            );
            userFound = true;
            setUserErrorHelperText("");
            setIsInvalidUserError(false);
          });
        });

        if (!userFound) {
          setrepLabel(<p className="error-message">Invalid sales rep id</p>);
          return;
        }
      });
  };

  return (
    <div className="first-form">
      <Paper className="form-background" elevation={9}>
        <div className="logo-container">
          <img className="logo" src={Logo} />
        </div>
        <form className="inner-form" onSubmit={handleSubmit(onSubmit)}>
          {repLabel}

          <Controller
            render={({ field }) => (
              <TextField
                label="Sales rep ID*"
                error={isInvalidUserError}
                helperText={userErrorHelperText}
                {...field}
                value={itemID}
                onChange={(e) => {
                  setItemId(e.target.value);
                }}
              />
            )}
            name="itemID"
            control={control}
            defaultValue={itemID}
          />

          <Controller
            render={({ field }) => (
              <TextField
                className="mui-button"
                size="Large"
                label="First name*"
                error={errors?.firstName ? true : false}
                helperText={errors?.firstName?.message}
                {...field}
              />
            )}
            name="firstName"
            rules={{
              required: "This field is required",
              maxLength: {
                value: 20,
                message: "First name cannot exceed 20 characters",
              },
              pattern: {
                value: /^[A-Za-z]+$/i,
                message: "Alphabetical characters only",
              },
            }}
            control={control}
            defaultValue=""
          />

          <Controller
            render={({ field }) => (
              <TextField
                label="Last name*"
                error={errors?.lastName ? true : false}
                helperText={errors?.lastName?.message}
                {...field}
              />
            )}
            name="lastName"
            rules={{
              required: "This field is required",
              maxLength: {
                value: 20,
                message: "Last name cannot exceed 20 characters",
              },
              pattern: {
                value: /^[A-Za-z]+$/i,
                message: "Alphabetical characters only",
              },
            }}
            control={control}
            defaultValue=""
          />

          <Controller
            render={({ field }) => (
              <TextField
                label="Email*"
                error={errors?.email ? true : false}
                helperText={errors?.email?.message}
                {...field}
              />
            )}
            name="email"
            rules={{
              required: "This field is required",
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Please enter a valid email",
              },
            }}
            control={control}
            defaultValue=""
          />

          <Controller
            render={({ field }) => (
              <TextField
                label="Phone number*"
                error={errors?.phone ? true : false}
                helperText={errors?.phone?.message}
                {...field}
              />
            )}
            name="phone"
            rules={{
              required: "This field is required",
              maxLength: {
                value: 10,
                message: "Number should be 10 digits",
              },
              minLength: {
                value: 10,
                message: "Number should be 10 digits",
              },
              pattern: {
                value: /^[0-9]*$/gm,
                message: "Please enter a valid phone number",
              },
            }}
            control={control}
            defaultValue=""
          />

          <Controller
            render={({ field }) => (
              <MuiAutoComplete
                {...field}
                options={languagesList}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Preferred Language*"
                    variant="outlined"
                  />
                )}
                onChange={(_, data) => field.onChange(data)}
              />
            )}
            name="language"
            control={control}
            defaultValue={"English"}
          />

          <Autocomplete
            className="google-autocomplete"
            options={{
              componentRestrictions: { country: "us" },
              types: ["address"],
            }}
            onPlaceSelected={(place) => {
              addressSelected = place.formatted_address;

              if (addressSelected !== undefined) {
                setGeometryLocation({
                  lat: place.geometry.location.lat(),
                  lng: place.geometry.location.lng(),
                });
                setMapCoordinates(
                  place.geometry.location.lat() +
                    ", " +
                    place.geometry.location.lng()
                );
                place.address_components.forEach((addressComponent) => {
                  if (
                    addressComponent.types[0] === "administrative_area_level_1"
                  ) {
                    MondayApiCallback(addressComponent.short_name);
                  }
                });
              } else {
                addressSelected = "";
              }
            }}
          />

          <div className="google-map-container">
            <GoogleMapReact
              defaultZoom={13}
              defaultCenter={{ lat: 35.0873, lng: -106.65 }}
              center={geometryLocation}
              options={{ mapTypeControl: true }}
            >
              <LocationPin
                lat={geometryLocation.lat}
                lng={geometryLocation.lng}
              />
              <Marker lat={35.0873} lng={-106.65} text="my marker" />
            </GoogleMapReact>
          </div>

          <Controller
            render={({
              field: { onChange, onBlur, value = mapCoordinates, ref },
            }) => <TextField label="Coordinates" value={mapCoordinates} />}
            name="coordinates"
            value={mapCoordinates}
            control={control}
            defaultValue=""
          />

          <Controller
            control={control}
            name="Date"
            render={({
              field: { onChange, onBlur, value = selectedDate, ref },
            }) => (
              <LocalizationProvider dateAdapter={DateAdapter}>
                <MobileDatePicker
                  disablePast
                  label="Date*"
                  value={value}
                  onChange={onChange}
                  selected={value}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            )}
          />
          <Controller
            control={control}
            name="Time"
            render={({
              field: { onChange, onBlur, value = timeValue, ref },
            }) => (
              <LocalizationProvider dateAdapter={DateAdapter}>
                <TimePicker
                  renderInput={(params) => <TextField {...params} />}
                  label="Time*"
                  value={value}
                  onChange={onChange}
                  minTime={minTime}
                  maxTime={maxTime}
                  shouldDisableTime={(timeValue, clockType) => {
                    if (clockType === "minutes" && timeValue % 15) {
                      return true;
                    }
                    return false;
                  }}
                />
              </LocalizationProvider>
            )}
          />

          <Controller
            render={({ field }) => (
              <TextField
                label="Appointment notes"
                multiline
                inputProps={multilineComponent}
                {...field}
              />
            )}
            name="appointmentNotes"
            control={control}
            defaultValue=""
          />

          <div className="align-left">
            <FormLabel component="legend">Utility &nbsp;</FormLabel>
            <Controller
              render={({ field }) => (
                <Select {...field}>{UtilitySelect}</Select>
              )}
              name="Utility"
              control={control}
              defaultValue={"N/A"}
            />
          </div>

          <div className="align-left">
            <FormLabel component="legend">Send calendar reminder</FormLabel>
          </div>
          <Controller
            render={({ field }) => (
              <RadioGroup aria-label="Send calendar reminder" {...field}>
                <div className="align-left">
                  <FormControlLabel value="no" control={<Radio />} label="No" />

                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                </div>
              </RadioGroup>
            )}
            name="calenderReminder"
            control={control}
            defaultValue={"no"}
          />
          <Controller
            render={({ field }) => (
              <TextField
                label="Design preference"
                multiline
                inputProps={multilineComponent}
                {...field}
              />
            )}
            name="designPreference"
            control={control}
            defaultValue=""
          />
          <div className="align-left">
            <FormLabel component="legend">Job source &nbsp;</FormLabel>
            <Controller
              render={({ field }) => (
                <Select {...field}>
                  <MenuItem value={"Direct To Home"}>Direct To Home</MenuItem>
                  <MenuItem value={"Retail/Events"}>Retail/Events</MenuItem>
                  <MenuItem value={"Online"}>Online</MenuItem>
                  <MenuItem value={"Referral"}>Referral</MenuItem>
                </Select>
              )}
              name="jobSource"
              control={control}
              defaultValue={"Direct To Home"}
            />
          </div>
          <div className="align-left">
            <FormLabel component="legend">Is a new roof required</FormLabel>
          </div>
          <Controller
            render={({ field }) => (
              <RadioGroup aria-label="Is a new roof required" {...field}>
                <div className="align-left">
                  <FormControlLabel
                    onChange={OnRoofRadioChange}
                    value="no"
                    control={<Radio />}
                    label="No"
                  />
                  <FormControlLabel
                    onChange={OnRoofRadioChange}
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                </div>
              </RadioGroup>
            )}
            name="roofRequired"
            control={control}
            defaultValue={"no"}
          />
          {roofFields}
          {submitting && <Alert severity="info">Submitting form</Alert>}

          {submitSuccess && (
            <Alert severity="success">Thank you for submitting your lead</Alert>
          )}

          {(Object.keys(errors).length > 0 || isInvalidUserError) && (
            <Alert severity="error">Please enter correct information</Alert>
          )}

          <Button variant="contained" disabled={submitting} type="submit">
            Submit Form
          </Button>
        </form>
      </Paper>
    </div>
  );
};
export default FirstForm;
